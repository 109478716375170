<template>
  <div class="container-fluid">
    <div class="col-12" id="f"></div>
    <div class="row" v-if="type == '' && hamla == ''">
        <div class="col-12">
            <br><br><br><br>
        </div>
        <div class="col-12 col-lg-6 g text-right" v-if="hamla_1">
            <button class="btn btn-lg btn-primary" @click="hamla = '1';">
                الباقة 2
            </button>
        </div>
        <div class="col-12 col-lg-6 g text-left" v-if="hamla_2">
            <button class="btn btn-lg btn-success" @click="hamla = '2'">
                الباقة 4
            </button>
        </div>
    </div>
    <div class="row" v-if="type == '' && hamla != ''">
        <div class="col-12">
            <br><br><br><br>
        </div>
        <div class="col-12 col-lg-6 g text-right" v-if="type_1">
            <button class="btn btn-lg btn-primary" @click="type = '1'; getcamps()">
                {{ hamla == '1' ? 'منى/مكة' : 'سكن منى' }}
            </button>
        </div>
        <div class="col-12 col-lg-6 g text-left" v-if="type_2">
            <button class="btn btn-lg btn-success" @click="type = '2';  getcamps()">
                عرفات
            </button>
        </div>
    </div>
    <div class="row" v-if="type != '' && hamla != ''">
        <div class="col-12 col-lg-4 c h">
            <div class="card">
                <div class="card-header">
                    <h5>
                        توزيع على السكن ( {{ type == '1' ? 'سكن منى' : 'عرفات' }} )
                    </h5>
                </div>
                <div class="card-footer">
                    <div class="col-12 text-center">
                        <button class="btn btn-primary" @click="arrangeNow()" v-if="!loading">
                            توزيع الان
                        </button>
                        <button class="btn btn-primary disabled" disabled v-if="loading">
                            جاري التوزيع...
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-8 c h">
            <div class="card">
                <div class="card-header">
                    <h5>
                        توزيع بالذكاء الاصطناعي
                    </h5>
                </div>
                <div class="card-footer">
                    <div class="form-group">
                      <h5 for="">تعليمات التوزيع</h5>
                      <textarea class="form-control form-control-lg" v-model="instructions" placeholder="اكتب هنا..." rows="8"></textarea>
                    </div>
                    <div class="col-12 text-center">
                        <button class="btn btn-primary" @click="AI()" v-if="!loading">
                            توزيع الان
                        </button>
                        <button class="btn btn-primary disabled" disabled v-if="loading">
                            جاري التوزيع...
                        </button>
                        <span v-if="loading" class="text-primary g">
                            <br><br>
                            {{ status }}
                            <br>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 g">
            <div class="card">
                <div class="card-header">
                    توزيع الحجاج
                </div>
                <div class="card-body">
                    <app-collapse>
                        <app-collapse-item
                        v-for="bus in camps"
                        :key="bus._id"
                        :title="bus.title + ' ('+bus.users.length+' من '+bus.limit+')'"
                        >
                        <div class="g">
                            <button class="btn btn-sm btn-primary" style="border-radius: 0px" @click="print(bus._id, bus.title)">
                                <i class="fa fa-print"></i>
                                طباعة كشف الحجاج
                            </button>
                        &nbsp;
                        <button class="btn btn-sm btn-outline-danger" style="border-radius: 0px" @click="emptyBus(bus)">
                            <i class="fa fa-times"></i>
                            تفريغ 
                        </button>
                        </div>
                        <div class="col-12 table-responsive" :class="'print_' + bus._id">
                            <table class="table table-sm table-bordered table-hover">
                                <thead>
                                    <th>
                                        الاسم
                                    </th>
                                    <th>
                                        رقم الهوية
                                    </th>
                                    <th>
                                        الجنس
                                    </th>
                                    <th>
                                        الهاتف
                                    </th>
                                    <th>
                                        السرير
                                    </th>
                                    <th>
                                        رقم الحجز
                                    </th>
                                    <th>
                                        الجنسية
                                    </th>
                                    <th>
                                        الحافلة
                                    </th>
                                    <th>
                                        قام
                                        بالحضور
                                    </th>
                                </thead>
                                <tbody>
                                    <tr v-for="u in bus.users" :key="u._id" :class="samebed(bus, u[type == '1' ? 'bed' : 'bed2'], type == '1' ? 'bed' : 'bed2')">
                                        <td>
                                            {{ u.name }}
                                        </td>
                                        <td>
                                            {{ u.number }}
                                        </td>
                                        <td>
                                            {{ u.gender }}
                                        </td>
                                        <td>
                                            {{ u.phone }}
                                        </td>
                                        <td>
                                            <span class="dnone">{{ u[type == '1' ? 'bed' : 'bed2'] }}</span> <input class="hideme" type="number" style="border: none; width: 40px" @change="editBed(type, u._id, u[type == '1' ? 'bed' : 'bed2'])" v-model="u[type == '1' ? 'bed' : 'bed2']">
                                        </td>
                                        <td>
                                            {{ u.order_id }}
                                        </td>
                                        <td>
                                            {{ u.national }}
                                        </td>
                                        <td>
                                            {{ u.bus_id }}
                                        </td>
                                        <td>
                                            <span v-html="bus.attends.map(function(x){return x.number}).includes(u.number) ? `<i class='fa text-success fa-check'></i> نعم` : `<i class='fa text-danger fa-times'></i> لا`"></span>
                                            <span v-if="!bus.attends.map(function(x){return x.number}).includes(u.number)" class="hideme" @click="attendNow(u.number, bus._id)">
                                                <u>
                                                    <a href="javascript:;">
                                                        تحضير الآن
                                                    </a>
                                                </u>
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        </app-collapse-item>
                    </app-collapse>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import Ripple from "vue-ripple-directive";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
export default {
    directives: {
        Ripple,
    },
    components: {
        AppCollapse,
        AppCollapseItem
    },
    data(){
        return {
            user: JSON.parse(localStorage.getItem("user")),
            type: "",
            camps: [],
            type_1: checkPer('camps_1'),
            type_2: checkPer('camps_2'),
            loading: false,
            editBede: "",
            hamla: "",
            hamla_2: checkPer("hamla_2"),
            hamla_1: checkPer("hamla_1"),
            instructions: "",
            status: "",
            operation_id: "",
            xxc: null
        }
    },
    beforeDestroy(){
        var g = this;
        if(g.xxc){
            clearInterval(xxc)
        }
    },
    created(){
        if(!checkPer('arrange')){
            this.$router.push('/pers')
            return;
        }
        var g = this;
        g.xxc = setInterval(() => {
            if(g.loading){
               $.post(api + '/admin/camps/arrange-ai-status', {
                    jwt: g.user.jwt,
                    operation_id: g.operation_id
                }).then(function(r){ 
                    g.status = r
                    g.getcamps()
                })
            }
        }, 3000);
    },
    methods: {
        AI(){
            var g = this;
            g.operation_id = Math.random().toString().split(".")[1]
            g.loading = true;
            $.post(api + '/admin/camps/arrange-ai', {
                jwt: g.user.jwt,
                type: g.type,
                hamla: g.hamla,
                instructions: g.instructions,
                operation_id: g.operation_id
            }).then(function(r){
                g.loading = false
                $("#f").html(r)
                g.getcamps();
            }).fail(function(){
                g.loading = false
                alert('حدث خطأ')
            })
        },
        samebed(u, bed, type){
            var count = 0;
            u.users.forEach(function(a){
                if(a[type] == bed){
                    count++
                }
            })
            if(count > 1){
                return "bg-light-danger"
            }
        },
        emptyBus(bus){
            var g = this;
            if(confirm("متأكد من التفريغ؟")){
                $.post(api + '/admin/camps/empty', {
                    jwt: g.user.jwt,
                    id: bus._id
                }).then(function(r){
                    g.getcamps()
                })
            }
        },
        arrangeNow(){
            var g = this;
            if(confirm("متأكد من اعادة التوزيع؟\nسوف يتم الغاء اي تعديلات يدوية سابقة.")){
            this.loading = true;
                $.post(api + '/admin/camps/arrange-1', {
                    jwt: g.user.jwt,
                    type: g.type,
                    hamla: g.hamla
                }).then(function(r){
                    $.post(api + '/admin/camps/arrange-2', {
                        jwt: g.user.jwt,
                        type: g.type,
                        hamla: g.hamla
                    }).then(function(r){
                        $.post(api + '/admin/camps/arrange-3', {
                            jwt: g.user.jwt,
                            type: g.type,
                            hamla: g.hamla
                        }).then(function(r){
                            $.post(api + '/admin/camps/arrange-4', {
                                jwt: g.user.jwt,
                                type: g.type,
                                hamla: g.hamla
                            }).then(function(r){
                                g.loading = false
                                g.getcamps();
                            })
                        })
                    })
                })
            }
        },
        getcamps(){
            var g = this;
            function compare( a, b ) {
                if ( a[g.type == '1' ? 'bed' : 'bed2'] < b[g.type == '1' ? 'bed' : 'bed2'] ){
                    return -1;
                }
                if ( a[g.type == '1' ? 'bed' : 'bed2'] > b[g.type == '1' ? 'bed' : 'bed2'] ){
                    return 1;
                }
                return 0;
            }
            $.post(api + '/admin/camps/list-with-users', {
                jwt: g.user.jwt,
                type: g.type,
                hamla: g.hamla
            }).then(function(r){
                var arr = [];
                r.response.forEach(function(a){
                    a.users = a.users.map(function(b){
                        b.bed = new Number(b.bed)
                        b.bed2 = new Number(b.bed2)
                        return b
                    })
                    a.users.sort( compare );
                    arr.push(a);
                })
                g.camps = arr.map(function(x){
                    if(!x.attends){
                        x.attends = []
                    }
                    return x;
                });
            })
        },
        editBed(i, o, e){
            var g = this;
            $.post(api + '/admin/camps/edit-' + (i == '1' ? 'bed' : 'bed2'), {
                jwt: g.user.jwt,
                id: o,
                bed: e
            }).then(function(r){
                g.getcamps()
            })
        },
        attendNow(number, camp_id){
            var g = this;
            $.post(api + '/admin/camps/attend', {
                jwt: g.user.jwt,
                number: number,
                camp_id: camp_id
            }).then(function(r){
                g.getcamps()
            })
        },
        print(id, title){
            var divToPrint = $(".print_" + id);
            var newWin= window.open("");
            newWin.document.write(`
            <style>
            table, td, th {
                border: 1px solid;
            }
            table {
                width: 100%;
                border-collapse: collapse;
            }
            html,body{
                direction: rtl
            }
        tr:nth-of-type(20n){
            page-break-after: always;
        }
        .x{
           position: relative;
           top: 100px
        }
        h5{
            position: absolute;
            top: -50px;
            right: 10px
        }
        .banner{
            width: 100%;
            position: fixed;
            top: 0px
        }
        .footer{
            width: 100%;
            position: fixed;
            bottom: 0px
        }
        tr:nth-child(even) {
            background-color: #f2f2f2;
        }
        @media print and (orientation: portrait) {
            .banner2{
                display: none
            }
            .footer2{
                display: none
            }
        }
        .hideme{
            display:none
        }
        @media print and (orientation: landscape) {
            .banner{
                display:none
            }
            .footer{
                display: none
            }
            .banner2{
                width: 100%;
                position: fixed;
                top: 0px
            }
            .footer2{
                width: 100%;
                position: fixed;
                bottom: 0px
            }
        }
            </style>
            `)
            newWin.document.write(`
            <img class='banner' src='images/print-banner.png'>
            <img class='banner2' src='images/land-banner.png'>
            <div class='x'>
            <h5>كشف الحجاج للسكن ${title}</h5>${divToPrint.html()}</div>
            <img class='footer' src='images/print-footer.png'>
            <img class='footer2' src='images/land-footer.png'>
        `);
            newWin.document.close()
            newWin.print();
        }
    }
}
</script>